import React from 'react';

import { Nav, Navbar, NavItem } from 'react-bootstrap';
import { IndexLinkContainer } from 'react-router-bootstrap';

import * as routes from './routes';

const Navigation = () =>
  <div>
    <Navbar>
      <IndexLinkContainer to={routes.HOME}>
        <Navbar.Brand>The River Documentary</Navbar.Brand>
      </IndexLinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <IndexLinkContainer to={routes.HOME}>
          <NavItem active={true} eventKey={1}>Home</NavItem>
        </IndexLinkContainer>
      </Nav>
      <Nav>
        <IndexLinkContainer to={routes.CREW}>
          <NavItem active={true} eventKey={1}>Crew</NavItem>
        </IndexLinkContainer>
      </Nav>
      <Nav>
        <IndexLinkContainer to={routes.ANNOUNCEMENTS}>
          <NavItem active={true} eventKey={1}>Announcements</NavItem>
        </IndexLinkContainer>
      </Nav>
      <Nav>
        <IndexLinkContainer to={routes.SCREENING}>
          <NavItem active={true} eventKey={1}>Screening</NavItem>
        </IndexLinkContainer>
      </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>

export default Navigation;