import React from 'react';
import {Col} from 'react-bootstrap'

import announcements_data from './announcements_data'
import AnnouncementTable from './AnnouncementTable'

const Announcements = () =>
  <div>
    <Col xs={1} md={2} />
    <Col xs={10} md={8} >
        <div style={{marginTop:"50px"}}/>
        <AnnouncementTable announcements={announcements_data} />
    </Col>
    <Col xs={1} md={2} />
  </div>

export default Announcements;