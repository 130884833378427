const annnouncements_data = [
  {
    date: "2020-11-15",
    content: "The River Documentary won the Social Justice Award in the Studio City Film Festival.",
    linkFlag: false
  },
  {
    date: "2019-11-15",
    content: "The River Documentary trailer screened at the Seattle Film Summit today!  Watch the trailer using the link below!",
    linkFlag: true,
    linkUrl: "https://drive.google.com/file/d/1tnSEpV44eGFn8Ai_khpavmOKF9gtKyWA/view?usp=sharing",
    linkText: "The River Documentary Trailer"
  },
  {
    date: "2019-10-13",
    content: "A rough cut of approximately 2.5 hours has been finished. Heather is reviewing it. The story is starting to flow. This is exciting!",
    linkFlag: false
  },
  {
    date: "2019-09-06",
    content: "The River Documentary Kickstarter campaign is fully funded!",
    linkFlag: true,
    linkUrl: "https://www.kickstarter.com/projects/theriverdocumentary/the-river-a-documentary-film",
    linkText: "The River Documentary on Kickstarter"
  },
  {
    date: "2019-08-24",
    content: "The River Documentary Kickstarter campaign is 40% funded!",
    linkFlag: true,
    linkUrl: "https://www.kickstarter.com/projects/theriverdocumentary/the-river-a-documentary-film",
    linkText: "The River Documentary on Kickstarter"
  },
  {
    date: "2019-08-07",
    content: "The River Documentary Kickstarter campaign has officially kicked off!",
    linkFlag: true,
    linkUrl: "https://www.kickstarter.com/projects/theriverdocumentary/the-river-a-documentary-film",
    linkText: "The River Documentary on Kickstarter"
  }
]

export default annnouncements_data;