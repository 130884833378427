import React from 'react';
import {Col, Row} from 'react-bootstrap'

const Crew = () =>
  <div>
    <Col xs={1} md={2} />
    <Col xs={10} md={8} >
      <Col xs={12} md={6} >
        <div style={{marginTop:"50px"}}/>
        <img
          src={"https://clintonolsonfs.s3-us-west-2.amazonaws.com/images/the_river/hpo_headshot_2020_95k.jpg"}
          alt={""}
          className={"img-responsive"}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "70%",
            maxWidth: "300px"}} />
        <h3>Producer</h3>
        <h3>Heather Pilder Olson</h3>
        <p>
        Heather has written, produced and directed several award-winning films. She was an associate 
        producer for the documentary Gold Balls, which premiered at the Seattle International Film 
        Festival (SIFF) in 2016 and screened on PBS television in 2020. She wrote the teleplay Crack 
        the Whip, which was selected for the Bigfoot Script Challenge Live Table Read at SIFF in 
        2019, and has also written the features 38 Minutes, Dodgers and Birth. She co-leads The Green 
        Room and teaches screenwriting at the Gig Harbor women’s prison.
        </p>
        <a href={"http://www.heatherpilderolson.com"}>www.heatherpilderolson.com</a>
        <div style={{marginTop:"50px"}}/>
        <img
          src={"https://clintonolsonfs.s3-us-west-2.amazonaws.com/images/the_river/RW+Headshot.jpg"}
          alt={""}
          className={"img-responsive"}
          style={{
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "70%",
            maxWidth: "300px"}} />
        <h3>Director</h3>
        <h3>Rick Walters</h3>
        <p>
        Rick is an accomplished director, producer and actor. He has recently worked in locations on 
        television shows in New York and Los Angeles including Power, Billions, Little America, and 
        was the director for Lockdown Theater productions. He wrote and directed the Phoenix Run web 
        series, which won several major awards. He is a 14-year US Army veteran and served a tour of 
        duty in Iraq. His work on The River includes much of his personal story. 
        </p>
        <div style={{marginTop:"50px"}}/>
        <h3>Additional Crew</h3>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Executive Producer</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Judy Isaacson</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Editor</div></Col>
          <Col xs={6} md={6} lg={6} ><div>David Wesley Phillips</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Producer</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Andrew Tribolini</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Producer</div></Col>
          <Col xs={6} md={6} lg={6} ><div>TJ Walker</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Lead Camera Operator</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Dan McComb</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Assistant Camera</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Chris McElroy</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Assistant Camera</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Jaron Olson</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Sound</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Carli Plute</div></Col>
        </Row>
        <div style={{marginTop:"20px"}}/>
        <Row>
          <Col xs={6} md={6} lg={6} ><div>Drone Operator</div></Col>
          <Col xs={6} md={6} lg={6} ><div>Mick Mason</div></Col>
        </Row>
        <div style={{marginTop:"50px"}}/>
      </Col>
    </Col>
    <Col xs={1} md={2} />
  </div>

export default Crew;