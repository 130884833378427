import React from 'react';
import {Col} from 'react-bootstrap';

const HomePage = () =>
<div>
  <Col xs={1} md={2} />
  <Col xs={10} md={8} >
    <img
      src={"https://clintonolsonfs.s3-us-west-2.amazonaws.com/images/the_river/SouthBank500W.jpg"}
      alt={""}
      className={"img-responsive"}
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "70%"}} />
    <h2 className="text-center">The River</h2>
    <h3 className="text-center">A Documentary Film</h3>
    <p>
      <br/>
      <b>The River</b> is a documentary about how communication and purpose play into
      the success and failures of managing the homeless encampment in Aberdeen, Washington.
      Director Rick Walters, a 14-year US Army veteran, will go to The River to live with and talk
      with the displaced inhabitants and search for meaning in their experience, and catharsis for his
      own battles with addiction and security.
    </p>
    <p>
      Communication is key. We will look closely at the ways local government, the media, and the
      general population identify the problem, and how they communicate about it. What should we
      be doing? What ARE we doing? What can we do better? Whose problem is it?
    </p>
    <p>
      We will show how purpose drives the City Council, the police, social services, and the wide
      variety of people who live at The River.
    </p>
    <p>
      Currently, around 100-150 people live homeless at The River Camp. Grays Harbor County has
      some of the highest per-capita rates of homelessness, unemployment, poverty, and addiction in
      Washington State, and faces unique challenges with a homeless population that includes many
      young veterans, people dealing with addiction and mental illness, as well as Native Americans.
      The City Council voted recently to sweep the tent community, and we intend to document the
      sweep, as well as the community&#39;s response. We plan to include the voices and stories of those
      living homeless, as well as commentary from city government, business owners, police, the
      Quinault Tribe, and the social service agencies working to help the homeless.
    </p>
    <p>
      Our goal is to make an unbiased film that shows the whole picture of how one small American
      town is trying to cope with homelessness. This is a microcosm of the national crisis of
      homelessness in the United States, and our hope is that our film might shed some light and
      present some possible solutions for others.
    </p>
    <p>
      Produced by Heather Pilder Olson, Andrew Tribolini and TJ Walker<br/>
      For questions or for more information, contact Heather Pilder Olson
      at <a href={"mailto:heatherp007@gmail.com"}>heatherp007@gmail.com</a> or 206-734-6975.
    </p>
    <p>For the latest news visit the film's <a href="https://www.facebook.com/TheRiverDocumentary">facebook page</a>.</p>
  </Col>
  <Col xs={1} md={2} />
</div>;

export default HomePage;