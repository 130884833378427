import React, {useState} from 'react';
import {Col, FormGroup, ControlLabel, FormControl, Modal, Button} from 'react-bootstrap'
import emailjs from 'emailjs-com';

const Screening = () => {
  const [requestName, setRequestName] = useState("");
  const [requestEmail, setRequestEmail] = useState("");
  const [requestMessage, setRequestMessage] = useState("");

  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("Set the title!");
  const [modalMessage, setModalMessage] = useState("Set the message!");

  const handleClose = () => setShow(false);

  const emailjsSerivceId = "service_drc61d8";
  const emailjsTemplateId = "template_jlu8z8e";
  const emailjsUserId = "user_cP537BjmlzMyM1nPDYtnh";

  return(
    <div>
      <Col xs={1} md={2} />
      <Col xs={10} md={8} >
      <h1>Request Screening Information</h1>
        <form>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              type="text"
              value={requestName}
              placeholder="Enter your name"
              onChange={handleNameChange}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              type="email"
              value={requestEmail}
              placeholder="Enter your email"
              onChange={handleEmailChange}
            />
          </FormGroup>
          
          <FormGroup>
            <ControlLabel>Message</ControlLabel>
            <FormControl 
              componentClass="textarea" 
              value={requestMessage}
              placeholder="Enter your message"
              onChange={handleMessageChange}
            />
          </FormGroup>

          <input type="button" value="Submit" className="btn btn--submit" onClick={handleSubmit} />
        </form>
      </Col>
      <Col xs={1} md={2} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

  function handleNameChange(e) {
    setRequestName(e.target.value); 
  };
  
  function handleEmailChange(e) { 
    setRequestEmail(e.target.value) 
  };
  
  function handleMessageChange(e) { 
    setRequestMessage(e.target.value) 
  };
  
  function handleSubmit() {
    emailjs.send(
      emailjsSerivceId,
      emailjsTemplateId,
      {
        message_html: 
          "<div>" +
            "<p><b>&nbsp;&nbsp;&nbsp;&nbsp;Name:&nbsp;</b>" + requestName + "</p>" + 
            "<p><b>&nbsp;&nbsp;&nbsp;&nbsp;Email:&nbsp;</b>" + requestEmail + "</p>" +
            "<p><b>&nbsp;&nbsp;&nbsp;&nbsp;Message:&nbsp;</b>" + requestMessage + "</p>" +
          "</div>",
        from_name: "The River Documentary Website"
      },
      emailjsUserId
    ).then(function(response) {
      setModalTitle("Success!");
      setModalMessage("The producer will get in touch with you shortly!");
      setShow(true);
    }, function(error) {
      setModalTitle("Sad Trombone, :(");
      setModalMessage("We were unable to send a message to the producer. " +
        "This probably a problem with the website that we try to fix soon!");
      setShow(true);
   })
  }
};

export default Screening;