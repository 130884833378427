import React from 'react';
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import Navigation from './Navigation';
import HomePage from './Home';
import Crew from './Crew';
import Announcements from './Announcements';
import Screening from './Screening';

import * as routes from './routes';

const App = () =>
  <Router>
    <div>
      <Navigation />
      <Route
        exact path={routes.LANDING}
        component={HomePage}
      />
      <Route
        exact path={routes.HOME}
        component={HomePage}
      />
      <Route
        exact path={routes.CREW}
        component={Crew}
      />
      <Route
        exact path={routes.ANNOUNCEMENTS}
        component={Announcements}
      />
      <Route
        exact path={routes.SCREENING}
        component={Screening}
      />
    </div>
  </Router>

export default App;