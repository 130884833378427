import React from 'react';
import {Col, Row} from 'react-bootstrap'

const AnnouncementTable = (props) =>
  <div>
    <hr/>
    {
      props.announcements.map((announcement, index) => (
      <div>
        <Row>
          <Col xs={5} md={3} >
            <span>{announcement.date}</span>
          </Col>
          <Col xs={7} md={9} >
            <span>{announcement.content}</span>
            {announcement.linkFlag === true &&
              <div>
                <div style={{marginTop:"20px"}}/>
                <span><a href={announcement.linkUrl}>{announcement.linkText} </a></span>
              </div>
            }
            
          </Col>
        </Row>
        <hr/>
      </div>
      ))
    }
  </div>

export default AnnouncementTable;